import React from "react";
import { Container } from "react-bootstrap";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../layout/index";
import "./success.less";
import Call from "../images/CALL_2.png";
import get from "lodash/get";

const Success = () => {
  const cmsData = useStaticQuery(graphql`
    {
      prismicGlobalData {
        dataRaw
      }
    }
  `);

  const globalData = get(cmsData, "prismicGlobalData.dataRaw", {});

  return (
    <Layout>
      <section className="success">
        <article className="success-wrapper">
          <Container>
            <h4>Thank You</h4>
            <p>Your Appointment has been Successfully Scheduled</p>
            <a
              href={`tel:+${globalData.phone_number}`}
              className="btn call-btn"
            >
              <img src={Call} alt="" />
              Click to contact our agent
            </a>
            {/* <button className='btn back-btn'  onClick={() => {history.go(-1);return false;}}>
                            Go Back
                        </button> */}
          </Container>
        </article>
      </section>
    </Layout>
  );
};

export default Success;
